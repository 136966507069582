import React from 'react';
import styled from 'styled-components';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Image from 'react-bootstrap/Image';
import { trackFacebookEvent, trackFacebookCustomEvent } from '../service/facebookPixel'; // Import the pixel service

const StyledNavbar = styled(Navbar)`
    background: linear-gradient(180deg, rgba(242,249,249,0.6587009803921569) 100%, rgba(255,255,21,0) 100%);
    z-index: 2;
    &&& {
        padding-top: 2px;
    }

    @media (max-width: 768px) {
        padding: 2px 10px 0 10px;
    }
`;

const StyledContainer = styled(Container)`
    &&& {
        padding: 5px 15px;
        z-index: 1;
    }

    @media (max-width: 768px) {
        justify-content: start !important;
        gap: 10px;
    }
`;

const StyledNav = styled(Nav)`
    &&& {
        font-family: Poppins, sans-serif;
        text-align: center;
        gap: 20px;
    }
`;

const StyledNavLink = styled(Nav.Link)`
    &&& {
        color: black;
        margin-right: 8px;
        letter-spacing: 1px;
        font-weight: 400;
        font-size: 14px;
    }

    @media (max-width: 768px) {
        text-align: left;
    }
`;

export default function AppNavbar({ stage }) {
    // Track link click events
    const handleLinkClick = (linkName) => {
        trackFacebookCustomEvent('NavbarLinkClicked', { linkName });
    };

    return (
        <StyledNavbar expand="lg">
            <StyledContainer>
                <Navbar.Toggle aria-controls="basic-navbar-nav"></Navbar.Toggle>
                <Navbar.Brand className="pb-0">
                    <Image src={require('../assets/images/bpilogo.png')} style={{ maxHeight: '54px' }} fluid />
                </Navbar.Brand>
                <Navbar.Collapse id="basic-navbar-nav" style={{ flexDirection: 'column' }}>
                    {!stage && (
                        <StyledNav className="justify-content-center">
                            <StyledNavLink 
                                href="https://bpims.com/bpi-safe-sakay/" 
                                target="_blank" 
                                onClick={() => handleLinkClick('About BPI Safe Sakay')}
                            >
                                ABOUT BPI SAFE SAKAY
                            </StyledNavLink>
                            <StyledNavLink 
                                href="#plans" 
                                onClick={() => handleLinkClick('Choose Your Plan')}
                            >
                                CHOOSE YOUR PLAN
                            </StyledNavLink>
                            <StyledNavLink 
                                href="#form-section" 
                                onClick={() => handleLinkClick('Get Covered')}
                            >
                                GET COVERED
                            </StyledNavLink>
                            <StyledNavLink 
                                href="#contact-us" 
                                id="contact-us-button" 
                                onClick={() => handleLinkClick('Contact Us')}
                            >
                                CONTACT US
                            </StyledNavLink>
                        </StyledNav>
                    )}

                    {['s', 'f'].includes(stage) && (
                        <StyledNav className="justify-content-center">
                            <StyledNavLink 
                                href="https://bpims.com/bpi-safe-sakay/" 
                                target="_blank" 
                                onClick={() => handleLinkClick('About BPI Safe Sakay')}
                            >
                                ABOUT BPI SAFE SAKAY
                            </StyledNavLink>
                            <StyledNavLink 
                                onClick={() => {
                                    const urlWithoutQueryParams = window.location.href.split('?')[0];
                                    window.history.pushState({}, '', `${urlWithoutQueryParams}#plan-section`);
                                    window.location.reload();
                                    handleLinkClick('Choose Your Plan');
                                }}
                            >
                                CHOOSE YOUR PLAN
                            </StyledNavLink>
                            <StyledNavLink 
                                onClick={() => {
                                    const urlWithoutQueryParams = window.location.href.split('?')[0];
                                    window.history.pushState({}, '', `${urlWithoutQueryParams}#form`);
                                    window.location.reload();
                                    handleLinkClick('Get Covered');
                                }}
                            >
                                GET COVERED
                            </StyledNavLink>
                            <StyledNavLink 
                                href="#contact-us" 
                                id="contact-us-button" 
                                onClick={() => handleLinkClick('Contact Us')}
                            >
                                CONTACT US
                            </StyledNavLink>
                        </StyledNav>
                    )}
                </Navbar.Collapse>
            </StyledContainer>
        </StyledNavbar>
    );
}
