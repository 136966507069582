import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Form, Button, Container, Dropdown, Spinner } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Select from 'react-select';
import ErrorModal from './Modal';
import ConfirmationModal from './ConfirmationModal';
import { trackFacebookEvent, trackFacebookCustomEvent } from '../service/facebookPixel';

const StyledContainer = styled(Container)`
    position: relative;
    background: #f4f4f2;
    color: white;
    padding: 2% 17% !important;

    @media (max-width: 600px) {
        padding: 20px 55px !important;
    }
`;

const StyledContainer2 = styled(Container)`
    background: #f4f4f2;
    color: white;
    padding: 2% 10% !important;

    @media (max-width: 500px) {
        // width to larger screen
        width: 100%;
        padding: 0 !important;
        margin: 0;
    }
`;

const StyledH1 = styled.h1`
    font-weight: 700;
    color: #32312f;
`;

const StyledP = styled.p`
    color: #32312f;
    font-size: 14px;
    font-weight: 500;
`;

const StyledForm = styled(Form)`
    .form-group-label {
        font-family: 'Poppins';
        font-weight: normal;
        font-size: 14px;
        color: #b4b0a9;
    }
`;

const StyledSubmitButton = styled(Button)`
    background-color: white !important;
    color: #b30000 !important;
    max-width: fit-content;
    padding: 4px 60px;
    border-radius: 4px;
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 14px;
    color: white;
    text-align: center;
`;

const Wrapper = styled.div`
    position: relative;
    width: 500px;
    height: 50px;
    display: flex;
    left: -430px;
    bottom: -310px;
    justify-content: center;
    align-items: center;
`;

const RotatedRectangle = styled.div`
    position: absolute;
    bottom: 0; /* Adjust the position */
    left: 0; /* Adjust the position */
    width: 500px; /* Rectangle size */
    height: 350px; /* Rectangle size */
    background-color: #bcd1e5;
    transform: rotate(-55deg); /* Rotate the rectangle */
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden; /* Ensure the image is clipped by the rectangle */
    border-radius: 50px; /* Add border radius */
    z-index: 1; /* Lower z-index to place it below the image */

    @media (min-width: 1600px) {
        // width to larger screen
        left: -18%;
    }
`;

const RectangleImage = styled.img`
    position: absolute;
    top: -845%; /* Adjust the vertical position of the image */
    left: 70%;
    transform: translateX(-50%); /* Center the image horizontally */
    width: 40%; /* Adjust the width to ensure the image is visible */
    height: auto; /* Maintain the aspect ratio of the image */
    z-index: 2; /* Higher z-index to place it above the rectangle */

    @media (min-width: 1600px) {
        // width to larger screen
        left: 55%;
    }
    @media (max-width: 500px) {
        width: 20%;
        left: 87%;
        top: -650%;
    }
`;

const Wrapper2 = styled.div`
    position: relative;
    width: 500px;
    height: 50px;
    display: flex;
    background-color: #bcd1e5;
    @media (min-width: 1400px) {
        // width to larger screen
        right: -1090px;
        top: -73%;
    }

    @media (min-width: 1600px) {
        right: -1500px;
        top: -73%;
    }

    @media (min-width: 300px) and (max-width: 767px) {
        display: none;
    }
`;

const RotatedRectangle2 = styled.div`
    position: absolute;
    top: -370%;
    right: -120%;
    width: 500px; /* Rectangle size */
    height: 275px; /* Rectangle size */
    background-color: #bcd1e5;
    transform: rotate(-20deg);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden; /* Ensure the image is clipped by the rectangle */
    border-radius: 50px; /* Add border radius */
    z-index: 1; /* Lower z-index to place it below the image */

    @media (min-width: 1400px) {
        // width to larger screen
        top: -310%;
        width: 450px;
        right: 13%;
    }

    @media (min-width: 1600px) {
        // width to larger screen
        top: -310%;
        width: 450px;
        right: 100px;
    }
`;

const RectangleImage2 = styled.img`
    position: absolute;
    right: -60%;
    top: -310%;
    transform: translateX(-50%); /* Center the image horizontally */
    width: 34%; /* Adjust the width to ensure the image is visible */
    height: auto; /* Maintain the aspect ratio of the image */
    z-index: 2; /* Higher z-index to place it above the rectangle */
    @media (min-width: 1400px) {
        // width to larger screen
        right: 64%;
        top: -270%;
    }

    @media (min-width: 1600px) {
        // width to larger screen
        right: 336px;
    }
`;

const SpinnerWrapper = styled.div`
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    display: block;
`;

export default function FormBody({
    handleData,
    data,
    handleSubmit,
    plan,
    planObject,
    formLoading,
    plans,
    setSelectedPlan,
    formRestrictions = {},
    handleReferrerValidate = async () => {},
    address,
    setAddress,
    loadingForm
}) {
    const [form, setForm] = React.useState(null);
    const [errors, setErrors] = React.useState([]);
    const [errorMessages, setErrorMessages] = React.useState({});
    const [isReferrerCodeDisabled, setIsReferrerCodeDisabled] = React.useState(false);
    const [reloadCount, setReloadCount] = React.useState(parseInt(localStorage.getItem('reloadCount') || '0', 10));

    const previousErrorsRef = React.useRef([]);
    const previousErrorsRef2 = React.useRef([]);
    const selectedPlanRef = useRef(null);

    const formRefs = {
        'FIRST NAME': useRef(),
        'MIDDLE NAME': useRef(),
        'LAST NAME': useRef(),
        'BIRTH DATE': useRef(),
        GENDER: useRef(),
        'MOBILE NUMBER': useRef(),
        EMAIL: useRef(),
        'REFERRER CODE': useRef(),
        TIN: useRef(),
        EMAIL: useRef(),
        PROVINCE: useRef(),
        CITY: useRef(),
        BARANGAY: useRef()
    };

    React.useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const referrerCode = queryParams.get('code');

        if (reloadCount === 0 && referrerCode) {
            handleData((prevData) => ({
                ...prevData,
                pref: referrerCode
            }));
            setIsReferrerCodeDisabled(true);
            setReloadCount(reloadCount + 1);
            localStorage.setItem('reloadCount', String(reloadCount + 1));
            queryParams.delete('code');
            const newUrl = window.location.pathname + '' + queryParams.toString();
            window.history.replaceState(null, '', newUrl);
        } else {
            localStorage.removeItem('reloadCount');
        }
    }, [reloadCount, handleData]);

    React.useEffect(() => {
        if (previousErrorsRef?.current?.length) {
            const errorField = previousErrorsRef.current[0];
            setTimeout(() => {
                formRefs[errorField].current.focus();
            }, 400);
        }

        previousErrorsRef.current = errors.slice() || [];
    }, [errors]);

    React.useEffect(() => {
        return () => {
            setForm(() => null);
            setErrors(() => []);
            setErrorMessages(() => ({}));
        };
    }, []);

    // Access the previous value using previousErrorsRef.current

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;

        handleData((p) => {
            return {
                ...p,
                [name]:
                    type === 'checkbox'
                        ? checked
                        : name === 'mobileNo'
                        ? value.replace(/[^0-9]/g, '').slice(0, 10) // for mobile number, 10 digits only
                        : name === 'tin'
                        ? value.replace(/[^0-9]/g, '').slice(0, 14) // for TIN, 14 digits only
                        : value
            };
        });
    };

    const handleCheckboxChange = () => {
        handleData((p) => ({ ...p, agreed: !p.agreed }));
    };

    const handleRecurringCheckboxChange = () => {
        handleData((p) => ({ ...p, recurring: !p.recurring }));
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        try {
            const formattedMobileNo = `+63-${data.mobileNo}`;
            const dataWithPrefix = { ...data, mobileNo: formattedMobileNo };
            await handleValidate(data);

            // Track form submission attempt (successful validation)
            trackFacebookEvent('SubmitForm', {
            firstName: data.firstName,
            lastName: data.lastName,
            mobileNo: data.mobileNo,
            dob: data.dob,
            gender: data.gender,
            email: data.email,
            province: data.province,
            city: data.city,
            barangay: data.barangay,
            referrerCode: data.pref,
        });

            setForm(() => dataWithPrefix);
            // handleSubmit(dataWithPrefix);
        } catch (error) {
            console.error('Error submitting form', error);
             // Track form validation failure
            trackFacebookEvent('Lead', { errorFields: error.message });
        }
    };

    const handleSubmitConfirm = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        try {
            const formattedMobileNo = `+63-${data.mobileNo}`;
            const dataWithPrefix = { ...data, mobileNo: formattedMobileNo };

             // Track form submission attempt
            trackFacebookEvent('SubmitForm', {
            firstName: data.firstName,
            lastName: data.lastName,
            mobileNo: data.mobileNo,
            dob: data.dob,
            gender: data.gender,
            email: data.email,
            province: data.province,
            city: data.city,
            barangay: data.barangay,
            referrerCode: data.pref,
        });

            handleSubmit(dataWithPrefix);
        } catch (error) {
            console.error('Error submitting form', error);
             // Track form validation failure
            trackFacebookEvent('Lead', { errorFields: error.message });
        }
    };

    const handleValidate = async (_form) => {
        const messages = {};
        const errors = [];
        previousErrorsRef2.current = [];
        const regex = /^(?=.*[a-zA-Z\sñÑ\s.\s-])[a-zA-Z0-9\sñÑ\s.\s-]+$/;
        // const tinRegex =
            // /^(?!0)(?!\d*([1-9])\1{3,})(?!0{8,})(?!0123456789)(?!987654321)(?!123456789)\d{14}|00000000000000$/;
        const tinRegex = 
            /^(?!\d*([1-9])\1{3,})(?!0{8,})(?!0123456789)(?!987654321)(?!123456789)\d{14}|00000000000000$/;

        // Errors
        if (!_form.firstName || !regex.test(_form.firstName || '')) {
            errors.push('FIRST NAME');
            messages['FIRST NAME'] = !_form.firstName ? 'First name is required' : 'First name is invalid';
        }

        if (_form.middleName && !regex.test(_form.middleName || '')) {
            errors.push('MIDDLE NAME');
            messages['MIDDLE NAME'] = 'Middle name is invalid';
        }

        if (!_form.lastName || !regex.test(_form.lastName || '')) {
            errors.push('LAST NAME');
            messages['LAST NAME'] = !_form.lastName ? 'Last name is required' : 'Last name is invalid';
        }

        if (!_form.mobileNo || (_form?.mobileNo?.length ?? '') < 10) {
            errors.push('MOBILE NUMBER');
            messages['MOBILE NUMBER'] = !_form.mobileNo
                ? 'Mobile number is required'
                : 'Mobile number must be 10 digits long';
        }

        if (!_form.dob) {
            errors.push('BIRTH DATE');
            messages['BIRTH DATE'] = 'Birth date is required';
        } else {
            const { dob } = formRestrictions;
            const minDate = new Date(dob?.min);
            const maxDate = new Date(dob?.max);
            const selectedDate = new Date(_form.dob);

            if (selectedDate < minDate || selectedDate > maxDate) {
                errors.push('BIRTH DATE');
                messages['BIRTH DATE'] = `Client must be between ${dob.minInt} and ${dob.maxInt} years old`;
            }
        }

        if (!_form.gender) {
            errors.push('GENDER');
            messages['GENDER'] = 'Gender is required';
        }

        if (!_form.email || !/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(_form.email)) {
            errors.push('EMAIL');
            messages['EMAIL'] = !_form.email ? 'Email is required' : 'Email is invalid';
        }

        if (!!_form.pref) {
            const isValid = await handleReferrerValidate(data.pref);
            if (!isValid) {
                errors.push('REFERRER CODE');
                messages['REFERRER CODE'] = 'Referrer code is invalid';
            }
        }

        if (!_form.tin || !tinRegex.test(_form.tin)) {
            errors.push('TIN');
            messages['TIN'] = "TIN must be 14 digits";
        }

        if (!_form.province) errors.push('PROVINCE');
        if (!_form.city) errors.push('CITY');
        if (!_form.barangay) errors.push('BARANGAY');

        // Test
        console.log('messages', messages);
        console.log('errors', errors);
        // Test

        if (errors.length) {
            previousErrorsRef2.current = errors.slice();
            setErrors(() => errors);
            setErrorMessages(() => ({ ...messages }));

             // Track validation failure with Pixel
            trackFacebookEvent('Lead', { errorFields: errors });

            throw new Error('Error on validation', errors);
        }

        return;
    };

    const setSelectedPlans = (event) => {
        const selectedPlan = plans.filter((plan) => plan.id == event.target.value);
        setSelectedPlan(selectedPlan[0]);
    };

    const setGender = (event) => {
        handleData((p) => ({ ...p, gender: event.target.value }));
        const index = previousErrorsRef2.current.indexOf('GENDER');
        if (index !== -1) {
            const _newArr = previousErrorsRef2.current.slice();
            _newArr.splice(index, 1);
            previousErrorsRef2.current = _newArr;
        }
    };

    const setSelectedType = (event) => {
        handleData((p) => ({ ...p, autoRenew: event.target.value == '1' ? true : false, recurring: false }));
    };

    React.useEffect(() => {
        if (plan !== undefined) {
            selectedPlanRef.current.value = plan;
        }
    }, [plan]);

    const filterCity = (province) => {
        const matchedCity = address.city.filter((item) => item.provinceCd === province.provinceCd);

        handleInputChange({ target: { name: 'province', value: province.provinceName } });
        handleInputChange({ target: { name: 'city', value: null } });
        handleInputChange({ target: { name: 'barangay', value: null } });
        handleInputChange({ target: { name: 'zipCode', value: '' } });

        setAddress({
            ...address,
            matchedCity: matchedCity,
            matchedBarangay: null
        });
    };

    const filterBarangay = (city) => {
        const matchedBarangay = address.barangay.filter(
            (item) => item.provinceCd === city.provinceCd && item.cityCd === city.cityCd
        );

        handleInputChange({ target: { name: 'city', value: city.cityName } });
        handleInputChange({ target: { name: 'barangay', value: null } });
        handleInputChange({ target: { name: 'zipCode', value: '' } });

        setAddress({
            ...address,
            matchedBarangay: matchedBarangay
        });
    };

    return (
        <StyledContainer fluid id="form-section">
            {loadingForm && (
                <SpinnerWrapper>
                    <Spinner animation="border" role="status" variant="dark" />
                </SpinnerWrapper>
            )}

            <Row className="mt-4" style={{ opacity: loadingForm ? '0.4' : '' }}>
                <StyledH1>Get covered today.</StyledH1>
                <StyledP>Fill-out the form below and activate your BPI Safe Sakay in minutes.</StyledP>
            </Row>

            <StyledForm style={{ opacity: loadingForm ? '0.4' : '', pointerEvents: loadingForm ? 'none' : '' }}>
                <Row>
                    <Col lg={4}>
                        <Form.Group className="mb-3">
                            <Form.Label
                                className="form-group-label"
                                style={{ fontFamily: 'Poppins', fontWeight: 'normal', fontSize: '14px' }}
                            >
                                FIRST NAME<span>*</span>
                            </Form.Label>
                            <Form.Control
                                type="text"
                                name="firstName"
                                value={data.firstName}
                                onChange={handleInputChange}
                                required
                                ref={formRefs['FIRST NAME']}
                                style={{
                                    borderColor: (previousErrorsRef2?.current ?? []).includes('FIRST NAME')
                                        ? 'red'
                                        : '',
                                    textTransform: 'uppercase'
                                }}
                                onFocus={() => {
                                    const index = previousErrorsRef2.current.indexOf('FIRST NAME');
                                    if (index !== -1) {
                                        const _newArr = previousErrorsRef2.current.slice();
                                        _newArr.splice(index, 1);
                                        previousErrorsRef2.current = _newArr;
                                    }
                                }}
                            />
                        </Form.Group>
                    </Col>

                    <Col lg={4}>
                        <Form.Group className="mb-3">
                            <Form.Label
                                className="form-group-label"
                                style={{ fontFamily: 'Poppins', fontWeight: 'normal', fontSize: '14px' }}
                            >
                                MIDDLE NAME
                            </Form.Label>
                            <Form.Control
                                type="text"
                                name="middleName"
                                value={data.middleName}
                                style={{
                                    borderColor: (previousErrorsRef2?.current ?? []).includes('MIDDLE NAME')
                                        ? 'red'
                                        : '',
                                    textTransform: 'uppercase'
                                }}
                                onChange={handleInputChange}
                                ref={formRefs['MIDDLE NAME']}
                                onFocus={() => {
                                    const index = previousErrorsRef2.current.indexOf('MIDDLE NAME');
                                    if (index !== -1) {
                                        const _newArr = previousErrorsRef2.current.slice();
                                        _newArr.splice(index, 1);
                                        previousErrorsRef2.current = _newArr;
                                    }
                                }}
                            />
                        </Form.Group>
                    </Col>

                    <Col lg={4}>
                        <Form.Group className="mb-3">
                            <Form.Label
                                className="form-group-label"
                                style={{ fontFamily: 'Poppins', fontWeight: 'normal', fontSize: '14px' }}
                            >
                                LAST NAME<span>*</span>
                            </Form.Label>
                            <Form.Control
                                type="text"
                                name="lastName"
                                value={data.lastName}
                                onChange={handleInputChange}
                                required
                                ref={formRefs['LAST NAME']}
                                style={{
                                    borderColor: (previousErrorsRef2?.current ?? []).includes('LAST NAME') ? 'red' : '',
                                    textTransform: 'uppercase'
                                }}
                                onFocus={() => {
                                    const index = previousErrorsRef2.current.indexOf('LAST NAME');
                                    if (index !== -1) {
                                        const _newArr = previousErrorsRef2.current.slice();
                                        _newArr.splice(index, 1);
                                        previousErrorsRef2.current = _newArr;
                                    }
                                }}
                            />
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col lg={4}>
                        <Form.Group className="mb-3">
                            <Form.Label
                                className="form-group-label"
                                style={{ fontFamily: 'Poppins', fontWeight: 'normal', fontSize: '14px' }}
                            >
                                BIRTH DATE<span>*</span>
                            </Form.Label>
                            <Form.Control
                                type="date"
                                name="dob"
                                value={data.dob ?? null}
                                onChange={handleInputChange}
                                required
                                ref={formRefs['BIRTH DATE']}
                                max={formRestrictions.dob?.max}
                                min={formRestrictions.dob?.min}
                                style={{
                                    borderColor: (previousErrorsRef2?.current ?? []).includes('BIRTH DATE')
                                        ? 'red'
                                        : '',
                                    textTransform: 'uppercase'
                                }}
                                onFocus={() => {
                                    const index = previousErrorsRef2.current.indexOf('BIRTH DATE');
                                    if (index !== -1) {
                                        const _newArr = previousErrorsRef2.current.slice();
                                        _newArr.splice(index, 1);
                                        previousErrorsRef2.current = _newArr;
                                    }
                                }}
                            />
                        </Form.Group>
                    </Col>

                    <Col lg={4}>
                        <Form.Group className="mb-3">
                            <Form.Label
                                className="form-group-label"
                                style={{ fontFamily: 'Poppins', fontWeight: 'normal', fontSize: '14px' }}
                            >
                                GENDER<span>*</span>
                            </Form.Label>
                            <Form.Select
                                size="sm"
                                name="gender"
                                ref={formRefs['GENDER']}
                                required
                                style={{
                                    textTransform: 'uppercase',
                                    borderColor: (previousErrorsRef2?.current ?? []).includes('GENDER') ? 'red' : ''
                                }}
                                onChange={setGender}
                            >
                                <option value="" disabled selected></option>
                                {[
                                    { name: 'MALE', value: 'm' },
                                    { name: 'FEMALE', value: 'f' }
                                ].map((type, index) => {
                                    return (
                                        <option
                                            value={type?.value}
                                            {...(type?.value === data.gender && { selected: true })}
                                            key={`type-${index}`}
                                        >
                                            {type?.name}
                                        </option>
                                    );
                                })}
                            </Form.Select>
                        </Form.Group>
                    </Col>

                    <Col lg={4}>
                        <Form.Group className="mb-3">
                            <Form.Label
                                className="form-group-label"
                                style={{ fontFamily: 'Poppins', fontWeight: 'normal', fontSize: '14px' }}
                            >
                                MOBILE NUMBER<span>*</span>
                            </Form.Label>
                            <div className="input-group">
                                <span
                                    className="input-group-text custom-input"
                                    style={{
                                        borderColor: (previousErrorsRef2?.current ?? []).includes('MOBILE NUMBER')
                                            ? 'red'
                                            : '',
                                        textTransform: 'uppercase' ? 'red' : ''
                                    }}
                                >
                                    +63
                                </span>
                                <Form.Control
                                    className="custom-input"
                                    type="tel"
                                    name="mobileNo"
                                    value={data.mobileNo}
                                    onChange={handleInputChange}
                                    pattern="[0-9]*"
                                    maxLength="10"
                                    required
                                    ref={formRefs['MOBILE NUMBER']}
                                    style={{
                                        borderColor: (previousErrorsRef2?.current ?? []).includes('MOBILE NUMBER')
                                            ? 'red'
                                            : '',
                                        textTransform: 'uppercase' ? 'red' : ''
                                    }}
                                    onFocus={() => {
                                        const index = previousErrorsRef2.current.indexOf('MOBILE NUMBER');
                                        if (index !== -1) {
                                            const _newArr = previousErrorsRef2.current.slice();
                                            _newArr.splice(index, 1);
                                            previousErrorsRef2.current = _newArr;
                                        }
                                    }}
                                />
                            </div>
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col lg={4}>
                        <Form.Group className="mb-3">
                            <Form.Label
                                className="form-group-label"
                                style={{ fontFamily: 'Poppins', fontWeight: 'normal', fontSize: '14px' }}
                            >
                                EMAIL<span>*</span>
                            </Form.Label>
                            <Form.Control
                                type="email"
                                name="email"
                                value={data.email}
                                onChange={handleInputChange}
                                required
                                ref={formRefs['EMAIL']}
                                style={{
                                    borderColor: (previousErrorsRef2?.current ?? []).includes('EMAIL') ? 'red' : '',
                                    textTransform: 'uppercase'
                                }}
                                onFocus={() => {
                                    const index = previousErrorsRef2.current.indexOf('EMAIL');
                                    if (index !== -1) {
                                        const _newArr = previousErrorsRef2.current.slice();
                                        _newArr.splice(index, 1);
                                        previousErrorsRef2.current = _newArr;
                                    }
                                }}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={4}>
                        <Form.Group className="mb-3">
                            <Form.Label
                                className="form-group-label"
                                style={{ fontFamily: 'Poppins', fontWeight: 'normal', fontSize: '14px' }}
                            >
                                TAXPAYER IDENTIFICATION NO.<span>*</span>
                            </Form.Label>
                            <Form.Control
                                type="text"
                                name="tin"
                                value={data.tin}
                                ref={formRefs['TIN']}
                                onChange={handleInputChange}
                                required
                                style={{
                                    borderColor: (previousErrorsRef2?.current ?? []).includes('TIN') ? 'red' : '',
                                    textTransform: 'uppercase'
                                }}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={4}>
                        <Form.Group className="mb-3">
                            <Form.Label
                                className="form-group-label"
                                style={{
                                    fontFamily: 'Poppins',
                                    fontWeight: 'normal',
                                    fontSize: '14px'
                                }}
                            >
                                PROVINCE<span>*</span>
                            </Form.Label>
                            <Select
                                name="province"
                                options={address?.province}
                                getOptionLabel={(option) => option.value}
                                getOptionValue={(option) => option.key}
                                value={address?.province?.find(
                                    (option) => option.value?.toLowerCase() === data.province?.toLowerCase()
                                )}
                                styles={{
                                    control: (provided) => ({
                                        ...provided,
                                        boxShadow: 'none',
                                        textTransform: 'uppercase',
                                        height: '30px',
                                        minHeight: '30px',
                                        padding: '0'
                                    }),
                                    valueContainer: (provided) => ({
                                        ...provided,
                                        height: '30px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        padding: '0 8px',
                                        marginBottom: '8px'
                                    }),
                                    input: (provided) => ({
                                        ...provided,
                                        height: '30px',
                                        margin: '-1px'
                                    }),
                                    option: (provided) => ({
                                        ...provided,
                                        color: 'black',
                                        textTransform: 'uppercase'
                                    }),
                                    dropdownIndicator: (provided) => ({
                                        ...provided,
                                        padding: '4px', // Adjust padding if needed
                                        marginBottom: '8px;'
                                    }),
                                    indicatorSeparator: (provided) => ({
                                        marginBottom: '20px;'
                                    })
                                }}
                                onChange={(selectedOption) => {
                                    filterCity(selectedOption);
                                }}
                                ref={formRefs['PROVINCE']}
                            />
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col lg={4}>
                        <Form.Group className="mb-3">
                            <Form.Label
                                className="form-group-label"
                                style={{
                                    fontFamily: 'Poppins',
                                    fontWeight: 'normal',
                                    fontSize: '14px'
                                }}
                            >
                                CITY / MUNICIPALITY <span>*</span>
                            </Form.Label>
                            <Select
                                name="city"
                                className={address?.matchedCity?.length ? '' : 'custom-readonly'}
                                options={address?.matchedCity}
                                getOptionLabel={(option) => option.value}
                                getOptionValue={(option) => option.key}
                                value={
                                    address?.matchedCity?.find(
                                        (option) => option.value?.toLowerCase() === data.city?.toLowerCase()
                                    ) || null
                                }
                                styles={{
                                    control: (provided) => ({
                                        ...provided,
                                        boxShadow: 'none',
                                        textTransform: 'uppercase',
                                        height: '30px',
                                        minHeight: '30px',
                                        padding: '0'
                                    }),
                                    valueContainer: (provided) => ({
                                        ...provided,
                                        height: '30px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        padding: '0 8px',
                                        marginBottom: '8px'
                                    }),
                                    input: (provided) => ({
                                        ...provided,
                                        height: '30px',
                                        margin: '-1px'
                                    }),
                                    option: (provided) => ({
                                        ...provided,
                                        color: 'black',
                                        textTransform: 'uppercase'
                                    }),
                                    dropdownIndicator: (provided) => ({
                                        ...provided,
                                        padding: '4px', // Adjust padding if needed
                                        marginBottom: '8px;'
                                    }),
                                    indicatorSeparator: (provided) => ({
                                        marginBottom: '20px;'
                                    })
                                }}
                                onChange={(selectedOption) => {
                                    filterBarangay(selectedOption);
                                }}
                                ref={formRefs['CITY']}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={4}>
                        <Form.Group className="mb-3">
                            <Form.Label
                                className="form-group-label"
                                style={{
                                    fontFamily: 'Poppins',
                                    fontWeight: 'normal',
                                    fontSize: '14px'
                                }}
                            >
                                BARANGAY<span>*</span>
                            </Form.Label>
                            <Select
                                name="barangay"
                                className={address?.matchedBarangay?.length ? '' : 'custom-readonly'}
                                options={address?.matchedBarangay}
                                getOptionLabel={(option) => option.value}
                                getOptionValue={(option) => option.key}
                                value={
                                    address?.matchedBarangay?.find(
                                        (option) => option.value?.toLowerCase() === data.barangay?.toLowerCase()
                                    ) || null
                                }
                                styles={{
                                    control: (provided) => ({
                                        ...provided,
                                        boxShadow: 'none',
                                        textTransform: 'uppercase',
                                        height: '30px',
                                        minHeight: '30px',
                                        padding: '0'
                                    }),
                                    valueContainer: (provided) => ({
                                        ...provided,
                                        height: '30px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        padding: '0 8px',
                                        marginBottom: '8px'
                                    }),
                                    input: (provided) => ({
                                        ...provided,
                                        height: '30px',
                                        margin: '-1px'
                                    }),
                                    option: (provided) => ({
                                        ...provided,
                                        color: 'black',
                                        textTransform: 'uppercase'
                                    }),
                                    dropdownIndicator: (provided) => ({
                                        ...provided,
                                        padding: '4px', // Adjust padding if needed
                                        marginBottom: '8px;'
                                    }),
                                    indicatorSeparator: (provided) => ({
                                        marginBottom: '20px;'
                                    })
                                }}
                                onChange={(selectedOption) => {
                                    handleInputChange({ target: { name: 'barangay', value: selectedOption.value } });
                                    handleInputChange({ target: { name: 'zipCode', value: selectedOption.bmCode } });
                                }}
                                ref={formRefs['BARANGAY']}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={4}>
                        <Form.Group className="mb-3">
                            <Form.Label
                                className="form-group-label"
                                style={{
                                    fontFamily: 'Poppins',
                                    fontWeight: 'normal',
                                    fontSize: '14px'
                                }}
                            >
                                ZIP CODE<span>*</span>
                            </Form.Label>
                            <Form.Control
                                type="text"
                                className={`form-group-input-custom custom-readonly`}
                                name="lastName"
                                readOnly
                                value={data.zipCode}
                                required
                            />
                        </Form.Group>
                    </Col>
                </Row>

                <Row className="mb-3">
                    <Col lg={4}>
                        <Form.Group>
                            <Form.Label
                                className="form-group-label"
                                style={{ fontFamily: 'Poppins', fontWeight: 'normal', fontSize: '14px' }}
                            >
                                REFERRER CODE
                            </Form.Label>
                            <Form.Control
                                type="text"
                                name="pref"
                                value={data.pref}
                                disabled={isReferrerCodeDisabled}
                                onChange={handleInputChange}
                                required
                                ref={formRefs['REFERRER CODE']}
                                style={{
                                    borderColor: (previousErrorsRef2?.current ?? []).includes('REFERRER CODE')
                                        ? 'red'
                                        : '',
                                    textTransform: 'uppercase'
                                }}
                                onFocus={() => {
                                    const index = previousErrorsRef2.current.indexOf('REFERRER CODE');
                                    if (index !== -1) {
                                        const _newArr = previousErrorsRef2.current.slice();
                                        _newArr.splice(index, 1);
                                        previousErrorsRef2.current = _newArr;
                                    }
                                }}
                            />
                        </Form.Group>
                    </Col>

                    <Col lg={4}>
                        <Form.Label
                            className="form-group-label"
                            style={{ fontFamily: 'Poppins', fontWeight: 'normal', fontSize: '14px' }}
                        >
                            PLAN<span>*</span>
                        </Form.Label>
                        <Form.Select
                            size="sm"
                            name="plan"
                            required
                            style={{
                                textTransform: 'uppercase'
                            }}
                            onChange={setSelectedPlans}
                            ref={selectedPlanRef}
                        >
                            <option value="" disabled selected>
                                Select Plan
                            </option>
                            {plans.length &&
                                plans.map((plan) => {
                                    return (
                                        <option value={plan?.id} key={plan?.id}>
                                            {plan.planName} - ₱ {(plan.premAmt ?? 0).toLocaleString('en-US') ?? 0}/Year
                                        </option>
                                    );
                                })}
                        </Form.Select>
                    </Col>
                </Row>

                <Row className="mb-3">
                    <Col lg={4}>
                        <Form.Label
                            className="form-group-label"
                            style={{ fontFamily: 'Poppins', fontWeight: 'normal', fontSize: '14px' }}
                        >
                            I want automatic yearly renewal of my coverage and charging of insurance premiums.
                            <span>*</span>
                        </Form.Label>
                        <Form.Select
                            size="sm"
                            name="auto-renew"
                            required
                            style={{
                                textTransform: 'uppercase'
                            }}
                            onChange={setSelectedType}
                        >
                            {[
                                { name: 'Yes', value: 1 },
                                { name: 'No', value: 0 }
                            ].map((type, index) => {
                                return (
                                    <option
                                        value={type?.value}
                                        {...(index === 0 && { selected: true })}
                                        key={`type-${index}`}
                                    >
                                        {type?.name}
                                    </option>
                                );
                            })}
                        </Form.Select>
                    </Col>
                </Row>

                <StyledContainer2>
                    <Row style={{ padding: '0 3px' }}>
                        <Form.Group xs={12} controlId="formCheckbox" style={{ color: 'black' }}>
                            <Form.Check
                                className="checkbox"
                                type="checkbox"
                                onChange={handleCheckboxChange}
                                checked={data.agreed}
                                name="agreed"
                                label={
                                    <p style={{ fontSize: '14px', marginTop: '-6px', marginLeft: '4px' }}>
                                        I have read, understood and agree to the{' '}
                                        <u>
                                            <a
                                                href="https://bpims.com/wp-content/uploads/2024/07/Terms-of-Use.pdf"
                                                target="bpims"
                                                style={{ color: 'black' }}
                                            >
                                                Terms of Use
                                            </a>
                                        </u>{' '}
                                        of this microsite, the{' '}
                                        <u>
                                            <a
                                                href="https://bpims.com/privacy-policy/"
                                                target="bpims"
                                                style={{ color: 'black' }}
                                            >
                                                BPI MS Privacy Policy
                                            </a>
                                            ,{' '}
                                        </u>
                                        the{' '}
                                        <a
                                            href="https://bpims.com/wp-content/uploads/2023/10/BPIMS_Stakeholder_Charter.pdf"
                                            target="bpims"
                                            style={{ color: 'black' }}
                                        >
                                            BPI MS Customer Charter
                                        </a>{' '}
                                        and the{' '}
                                        <u>
                                            <a
                                                href="https://bit.ly/BPISafeSakay_TCs"
                                                target="bpims"
                                                style={{ color: 'black' }}
                                            >
                                                Terms and Conditions of this Policy
                                            </a>
                                        </u>
                                        , issued and underwritten by BPI/MS Insurance Corporation.
                                    </p>
                                }
                            />
                        </Form.Group>
                    </Row>

                    {data.autoRenew && (
                        <Form.Group controlId="recurringConsent" style={{ color: 'black' }}>
                            <Form.Check
                                className="checkbox"
                                type="checkbox"
                                onChange={handleRecurringCheckboxChange}
                                checked={data.recurring}
                                label={
                                    <p style={{ fontSize: '14px', marginTop: '-6px', marginLeft: '4px' }}>
                                        I confirm and allow BPI MS to automatically renew my Personal Accident Insurance
                                        yearly upon its expiry and automatically charge my Debit card or Credit card
                                        yearly for the corresponding annual insurance premium. I understand and agree
                                        that if I want to cancel the yearly automatic charging on my Debit Card or
                                        Credit Card and automatic renewal of my Personal Accident Insurance coverage, I
                                        will need to contact BPI MS at{' '}
                                        <a href="tel:+63288409000" style={{ color: 'black' }}>
                                            (02) 8840-9000
                                        </a>{' '}
                                        or send an email at{' '}
                                        <a href="mailto:helpline@bpims.com" style={{ color: 'black' }}>
                                            helpline@bpims.com
                                        </a>{' '}
                                        prior to the expiry date of my Personal Accident Insurance coverage.
                                    </p>
                                }
                            />
                        </Form.Group>
                    )}
                </StyledContainer2>

                <div className="d-grid gap-2 justify-content-center">
                    <StyledSubmitButton
                        className="btn-submit"
                        variant="danger"
                        type="button"
                        disabled={formLoading || !data.agreed || (data.autoRenew && !data.recurring) || !planObject.id}
                        onClick={handleFormSubmit}
                    >
                        PAY NOW
                    </StyledSubmitButton>
                </div>
            </StyledForm>

            <ErrorModal
                show={errors.length > 0}
                handleClose={() => setErrors(() => [])}
                errorMessage={
                    errors.length
                        ? `<ul>${errors
                              .map(
                                  (field) =>
                                      `<li>${errorMessages[field] || `Required field or invalid format: ${field}`}</li>`
                              )
                              .join('')}</ul>`
                        : ''
                }
            />
            <ConfirmationModal
                show={form}
                handleClose={() => setForm()}
                plan={planObject}
                handleSubmit={handleSubmitConfirm}
            />

            <Wrapper>
                <RotatedRectangle />
                <RectangleImage src={require('../assets/images/origami-bird.png')} alt="Rectangle Image" />
            </Wrapper>

            <Wrapper2>
                <RotatedRectangle2 />
                <RectangleImage2 src={require('../assets/images/umbrella.png')} alt="Rectangle Image" />
            </Wrapper2>
        </StyledContainer>
    );
}
