import React, { useEffect} from 'react';
import styled from 'styled-components';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';

import AppNavbar from './components/AppNavBar';
import Landing from './components/Landing';
import LandingSuccess from './components/LandingSuccess';
import LandingFailed from './components/LandingFailed';
import Benefits from './components/Benefits';
import Plans from './components/Plans';
import Form from './components/Form';
import AboutUs from './components/AboutUs';
import Claim from './components/Claim';
import Footer from './components/Footer';
// import Trademark from './components/Trademark';

import submitForm from './service/submitForm';
import getTransactionStatus from './service/getTransactionStatus';

import getPlans from './service/getPlans';
import getRestrictions from './service/getRestrictions';
import validateReferrerCode from './service/validateReferrerCode';
import getAddress from './service/getAddress';
import { initFacebookPixel } from './service/facebookPixel';

import './App.css';

const MainContainer = styled(Container)`
    &&& {
        position: relative;
        overflow-x: clip;
        height: fit-content;
    }
`;

const Loading = styled.div`
    position: sticky;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    z-index: 2000;
`;

function App() {
    const queryParams = new URLSearchParams(window.location.search);
    const queryParamValue = queryParams.get('stage');

    const [loading, setLoading] = React.useState(false);
    const [loadingPlans, setLoadingPlans] = React.useState(false);
    const [loadingForm, setLoadingForm] = React.useState(false);
    const [allowReload, setAllowReload] = React.useState(false);
    const [selectedPlan, setSelectedPlan] = React.useState({});
    const [restrictions, setRestrictions] = React.useState({});
    const [address, setAddress] = React.useState({});
    const [form, setForm] = React.useState({
        autoRenew: true
    });
    const [plans, setPlans] = React.useState([]);

    React.useEffect(() => {
        if (queryParamValue && queryParamValue != 's' && queryParamValue != 'f') {
            setLoading(true);
            handleTransactionStatus(queryParamValue, () =>
                handleTransactionStatus(queryParamValue, () => {
                    setAllowReload(() => true);
                    handleTransactionStatus(queryParamValue, () =>
                        handleTransactionStatus(queryParamValue, () =>
                            handleTransactionStatus(queryParamValue, () => {
                                setTimeout(() => window.location.reload(), 5000);
                            })
                        )
                    );
                })
            );
        }
    }, []);

    React.useEffect(() => {
        if (selectedPlan.id) {
            const formSection = document.getElementById('form-section');
            if (formSection) formSection.scrollIntoView({ behavior: 'smooth' });
        }
    }, [selectedPlan]);

    React.useEffect(() => {
        handleRestrictions();
        handlePlans();
        handleAddress();
    }, []);

    const handlePlans = async () => {
        if (loadingPlans) return;

        setLoadingPlans(true);
        setPlans([]);
        try {
            const _plans = await getPlans();
            const { plan } = _plans.data;
            setPlans(plan.slice());
        } catch (error) {
            console.error('Error fetching plans:', error);
        } finally {
            setLoadingPlans(false);
            const url = new URL(window.location.href);
            const hash = url.hash;
            const formElement = document.getElementById('form-section');
            const plansElement = document.getElementById('plans');
            if (hash && hash === '#form' && formElement) {
                formElement.scrollIntoView({ behavior: 'smooth' });
            } else if (hash && hash === '#plan-section' && plansElement) {
                plansElement.scrollIntoView({ behavior: 'smooth' });
            }
        }
    };

    const handleAddress = async () => {
        try {
            setLoadingForm(true);
            const _address = await getAddress();
            const { province, city, barangay } = _address;
            console.log('address', _address);

            const mappedProvince = province.map((item) => {
                return { ...item, key: item.provinceName, value: item.provinceName };
            });

            const mappedCity = city.map((item) => {
                return { ...item, key: item.cityName, value: item.cityName };
            });

            const mappedBarangay = barangay.map((item) => {
                return { ...item, key: item.postalDesc, value: item.postalDesc };
            });

            setAddress({
                province: mappedProvince,
                city: mappedCity,
                barangay: mappedBarangay
            });
            setLoadingForm(false);
        } catch (error) {
            console.error('Error fetching address:', error);
            setLoadingForm(false);
        } finally {
            setLoadingForm(false);
        }
    };

    const handleRestrictions = async () => {
        try {
            const _restrictions = await getRestrictions();
            setRestrictions((p) => ({ ...p, ..._restrictions }));
        } catch (error) {
            console.error('Error fetching restrictions:', error);
        }
    };

    const validateReffererCode = async (params) => {
        try {
            const _validateRes = await validateReferrerCode(params);

            return _validateRes;
        } catch (error) {
            console.error('Error validating refferer code:', error);
        }
    };

    const handleSubmit = async () => {
        if (loading) return;
        document.body.style.overflow = 'hidden';

        setLoading(true);
        try {
            const res = await submitForm({ ...form, planId: selectedPlan?.id });
            const { Location } = res;
            if (Location) {
                window.location.href = Location;
            }
            setSelectedPlan({});
            setForm({});
        } catch (error) {
            console.error('API Call error', error);
        } finally {
            setLoading(false);
            document.body.style.overflow = '';
        }
    };

    const handleTransactionStatus = async (tranxRef, callback = () => {}) => {
        try {
            document.body.style.overflow = 'hidden';
            const res = await getTransactionStatus({ tranxRef });
            const { policyStatus } = res;

            if (['s', 'f'].includes(policyStatus)) window.location.href = `/?stage=${policyStatus}`;
            if (!callback) return;
            callback(); // retry if it fails
        } catch (error) {
            console.error('API handleTransactionStatus Call error', error);
        }
    };

    React.useEffect(() => {
        console.log('app.js data: ', form);
    }, [form]);

    useEffect(() => {
        // Initialize Facebook Pixel with your Ads Manager Pixel ID
        const pixelId = "2014372368982869";
        initFacebookPixel(pixelId);
    }, []);

    return (
        <MainContainer className="p-0" fluid>
            {loading && (
                <Loading>
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </Loading>
            )}
            {allowReload && (
                <Alert
                    variant="warning"
                    style={{
                        zIndex: 5000,
                        position: 'absolute',
                        top: '5%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)'
                    }}
                >
                    It seems your transaction is taking longer than expected. We will retry in a few seconds.
                </Alert>
            )}
            <Row>
                <AppNavbar stage={queryParamValue} />
            </Row>
            <Container fluid className="p-0">
                <Row>
                    {!queryParamValue && <Landing />}
                    {queryParamValue === 's' && <LandingSuccess />}
                    {queryParamValue === 'f' && <LandingFailed />}
                </Row>
                {!queryParamValue && (
                    <>
                        <Row className="mb-5 mt-5">
                            <Benefits />
                        </Row>
                        <Row className="mb-5">
                            <Plans handlePlanSelection={setSelectedPlan} plans={plans} loading={loadingPlans} />
                        </Row>
                    </>
                )}
                {!queryParamValue && (
                    <Row>
                        <Form
                            data={form}
                            handleData={setForm}
                            handleSubmit={handleSubmit}
                            plan={selectedPlan?.id}
                            planObject={selectedPlan}
                            formLoading={loading}
                            plans={plans}
                            setSelectedPlan={setSelectedPlan}
                            formRestrictions={restrictions}
                            handleReferrerValidate={validateReffererCode}
                            address={address}
                            setAddress={setAddress}
                            loadingForm={loadingForm}
                        />
                    </Row>
                )}
                <Row>
                    <Claim />
                </Row>
                <Row>
                    <Footer />
                </Row>
            </Container>
        </MainContainer>
    );
}

export default App;
