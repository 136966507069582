import React from 'react';
import styled from 'styled-components';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import bannerFailed from '../assets/images/banner_failed.png';
import { trackFacebookCustomEvent } from '../service/facebookPixel';

const LandingContainer = styled(Container)`
    background: url(${bannerFailed}) no-repeat center center;
    background-size: cover;
    height: 110vh;
    margin-top: -250px;

    @media (min-width: 300px) and (max-width: 767px) {
        margin-top: 0;
        height: 50vh;
    }

    @media (min-width: 768px) and (max-width: 992px) {
        margin-top: 0;
        height: 110vh;
    }

    @media (max-height: 300px) and (min-width: 500px) and (max-width: 700px) {
       height: 130vh;
    }
`;

const Title = styled.h1`
    color: #b30000;
    font-family: Poppins;
    font-size: 28px;
    font-weight: 700;
    position: absolute;
    left: 25%;
    width: 30%;
    top: 9%;

    @media (min-width: 300px) and (max-width: 767px) {
        font-size: 20px;
        left: 10%;
        width: 80%;
        position: sticky;
    }

    @media (min-width: 768px) and (max-width: 992px) {
        position: sticky;
        width: 50%;
        left: 15%;
    }
`;

const Description = styled.p`
    color: black;
    font-size: 14px;
    font-family: Poppins;
    padding-right: 4rem;
    position: absolute;
    left: 25%;
    width: 33%;
    top: 15%;

    @media (min-width: 300px) and (max-width: 767px) {
        font-size: 12px;
        top: 10%;
        left: 10%;
        width: 80%;
        position: sticky;
    }

    @media (min-width: 768px) and (max-width: 992px) {
        position: sticky;
        width: 50%;
        left: 15%;
    }
`;

const Description1 = styled.p`
    color: black;
    font-size: 14px;
    font-family: Poppins;
    padding-right: 4rem;
    position: absolute;
    left: 25%;
    width: 33%;
    top: 21%;

    @media (min-width: 300px) and (max-width: 767px) {
        font-size: 12px;
        top: 15%;
        left: 10%;
        width: 80%;
        position: sticky;
    }

    @media (min-width: 768px) and (max-width: 992px) {
        font-size: 14px;
        top: 15%;
        left: 15%;
        width: 50%;
        position: sticky;
    }
`;

const StyledButtonContainer = styled.div`
    top: 31%;
    left: 25%;
    transform: translate(0, -50%);
    position: absolute;

    @media (min-width: 300px) and (max-width: 767px) {
        position: sticky;
        margin-top: 20px;
        padding: 0% 10%;
    }
    
    @media (min-width: 768px) and (max-width: 992px) {
        position: sticky;
        margin-top: 20px;
        padding: 0% 15%;
    }
`;

const StyledButton = styled.a`
    border-radius: 4px;
    border: 1px solid #b30000;
    color: #b30000;
    background: white;
    z-index: 1;
    transition: all 0.2s ease-in 0s;
    padding: 6px 60px;
    font-size: 14px;
    text-decoration: none;

    &:hover {
        background: #b30000;
        color: white;
        border-color: #b30000;
    }

    @media (max-width: 500px) {
        // width to larger screen
        padding: 6px 40px;
        font-size: 12px;
    }
`;

export default function Landing() {
    const handleRetryClick = () => {
        // Track the button click with Facebook Pixel
        trackFacebookCustomEvent('TransactionFailed', { action: 'Retry Payment' });
        
        // Redirect and refresh
        const urlWithoutQueryParams = window.location.href.split('?')[0];
        window.history.pushState({}, '', `${urlWithoutQueryParams}#form`);
        window.location.reload();
    };

    return (
        <LandingContainer fluid>
            <Col>
                <Container fluid className="p-0">
                    <Row fluid className="pb-2 mt-5">
                        <Col>
                            <Title>Oops, transaction couldn't be processed.</Title>
                        </Col>
                    </Row>
                    <Row fluid>
                        <Col xs={12}>
                            <Description>
                                We encountered an issue while processing your payment with the selected method and
                                account. Your coverage is not yet active.
                            </Description>
                        </Col>
                    </Row>
                    <Row fluid>
                        <Col xs={12}>
                            <Description1>
                                Consider trying again with an alternate payment method or account. Also, ensure your
                                chosen account has sufficient funds and that online transactions are enabled in your
                                settings.
                            </Description1>
                        </Col>
                    </Row>
                    <Row fluid>
                        <Col>
                            <StyledButtonContainer>
                                <StyledButton onClick={handleRetryClick}>
                                    TRY AGAIN
                                </StyledButton>
                            </StyledButtonContainer>
                        </Col>
                    </Row>
                </Container>
            </Col>
        </LandingContainer>
    );
}
