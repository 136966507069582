import React from 'react';
import styled from 'styled-components';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import bannerSuccess from '../assets/images/banner_success.png';
import { trackFacebookEvent, trackFacebookCustomEvent } from '../service/facebookPixel';
import { useEffect } from 'react';

const LandingContainer = styled(Container)`
    background: url(${bannerSuccess}) no-repeat center center;
    background-size: cover;   
    height: 90vh;

    @media (min-width: 300px) and (max-width: 767px) {
        margin-top: 0;
        height: 75vh;
        background-position: 65%; // Center background image
        max-width: 100%;
    }

    @media (min-width: 768px) and (max-width: 992px) { 
        margin-top: 0;
        height: 120vh;
    }

    @media (max-height: 300px) and (min-width: 500px) and (max-width: 700px) {
        height: 150vh;
    }
`;

const StyledColumn = styled(Col)`
    @media (min-width: 300px) and (max-width: 767px) {
        height: 100vh;
        padding-top: 20px;
        background-color: rgba(255, 255, 255, 0.7);
    }
`;

const Title = styled.h1`
    color: #B30000;
    font-family: Poppins;
    font-size: 24px;
    font-weight: 700;
    position: absolute;
    left: 18%;
    top: 7.5%;

    @media (min-width: 300px) and (max-width: 767px) { // width to larger screen
        font-size: 20px;
        position: sticky;
        text-align: left;
        padding: 0 10%;
    }

    @media (min-width: 768px) and (max-width: 992px) { // width to larger screen
        position: sticky;
        font-size: 22px;
        padding: 0 10%;
        top
    }
`;

const Title2 = styled.h1`
    color: #b30000;
    font-family: Poppins;
    font-size: 22px;
    font-weight: 700;
    position: absolute;
    left: 18%;
    top: 24%;
    width: 38%;

    @media (min-width: 300px) and (max-width: 767px) {
        // width to larger screen
        font-size: 16px;
        top: 19%;
        left: 10%;
        width: 50%;
        position: sticky;
    }

    @media (min-width: 768px) and (max-width: 992px) {
        // width to larger screen
        font-size: 20px;
        top: 19%;
        left: 10%;
        width: 50%;
        position: sticky;
    }
`;

const Description = styled.p`
    color: black;
    font-size: 15px;
    font-family: Poppins;
    padding-right: 4rem;
    position: absolute;
    top: 11%;
    left: 18%;
    width: 30%;

    @media (min-width: 300px) and (max-width: 767px) {
        // width to larger screen
        font-size: 12px;
        left: 10%;
        position: sticky;
        width: 80%;
    }

    @media (min-width: 768px) and (max-width: 992px) {
        // width to larger screen
        font-size: 14px;
        width: 50%;
        left: 10%;
        position: sticky;
    }
`;

const Description1 = styled.p`
    color: black;
    font-size: 15px;
    font-family: Poppins;
    padding-right: 4rem;
    position: absolute;
    top: 16%;
    left: 18%;
    width: 30%;

    @media (min-width: 300px) and (max-width: 767px) {
        // width to larger screen
        font-size: 12px;
        top: 12%;
        left: 10%;
        width: 80%;
        position: sticky;
    }

    @media (min-width: 768px) and (max-width: 992px) {
        // width to larger screen
        font-size: 14px;
        top: 8%;
        left: 10%;
        width: 50%;
        position: sticky;
    }
`;

const Description2 = styled.p`
    color: black;
    font-size: 15px;
    font-family: Poppins;
    padding-right: 4rem;
    position: absolute;
    top: 22%;
    left: 18%;
    width: 30%;

    @media (min-width: 300px) and (max-width: 767px) {
        // width to larger screen
        font-size: 12px;
        top: 17%;
        left: 10%;
        width: 80%;
        position: sticky;
    }

    @media (min-width: 768px) and (max-width: 992px) {
        // width to larger screen
        font-size: 14px;
        top: 17%;
        left: 10%;
        width: 50%;
        position: sticky;
    }
`;

const Description3 = styled.p`
    color: black;
    font-size: 15px;
    font-family: Poppins;
    padding-right: 4rem;
    position: absolute;
    top: 28.5%;
    left: 18%;
    width: 30%;
    
    @media (min-width: 300px) and (max-width: 767px) {
        // width to larger screen
        font-size: 12px;
        left: 10%;
        width: 80%;
        position: sticky;
    }

    @media (min-width: 768px) and (max-width: 992px) {
        // width to larger screen
        font-size: 14px;
        top: 19%;
        left: 10%;
        width: 50%;
        position: sticky;
    }
`;

const StyledButtonContainer = styled.div`
    position: absolute;
    left: 18%;
    top: 36%;
    transform: translate(0, -50%);

    @media (min-width: 300px) and (max-width: 767px) {
        // width to larger screen
        position: sticky;
        margin-top: 20px;
        padding: 2% 10%;
    }

    @media (min-width: 768px) and (max-width: 992px) {
        // width to larger screen
        position: sticky;
        margin-top: 20px;
        padding: 2% 10%;
    }
`;

const StyledButton = styled.a`
    border-radius: 4px;
    border: 1px solid #b30000;
    color: #b30000;
    background: white;
    z-index: 1;
    transition: all 0.2s ease-in 0s;
    padding: 6px 32px;
    font-size: 14px;
    text-decoration: none;
    
    &:hover {
        background: #b30000;
        color: white;
        border-color: #b30000;
    }

    @media (min-width: 300px) and (max-width: 767px) {
        // width to larger screen
        padding: 4px 26px;
        font-size: 10px;
    }
`;

export default function Landing() {
    useEffect(() => {
        trackFacebookEvent('PageView', { page: 'Success Landing Page' });
        trackFacebookCustomEvent('PurchaseSuccess', {
            status: 'success',
            message: 'User completed purchase and landed on success page',
        });
    }, []);

    const handleButtonClick = () => {
        trackFacebookEvent('ButtonClick', {
            buttonName: 'Visit Express Protect',
            page: 'Success Landing Page',
        });
    };
    return (
        <LandingContainer fluid>
            <StyledColumn>
                <Container fluid className="p-0">
                    <Row fluid className="pb-2 mt-5">
                        <Col>
                            <Title>Sakay na with peace of mind!</Title>
                        </Col>
                    </Row>
                    <Row fluid>
                        <Col xs={12}>
                            <Description>
                                Your BPI Safe Sakay Confirmation of insurance coverage is on its way to your registered
                                email.
                            </Description>
                        </Col>
                    </Row>
                    <Row fluid>
                        <Col xs={12}>
                            <Description1>
                                You can now enjoy 24/7 worldwide accident coverage valid for one (1) year, and
                                motorcycle-related accidents are covered, too.
                            </Description1>
                        </Col>
                    </Row>
                    <Row fluid>
                        <Col xs={12}>
                            <Description2>Sakay na!</Description2>
                        </Col>
                    </Row>
                    <Row fluid>
                        <Col>
                            <Title2>Cover what you value with BPI MS Express Protect</Title2>
                        </Col>
                    </Row>
                    <Row fluid>
                        <Col xs={12}>
                            <Description3>
                                Protect your dreams and investments by insuring your car, home, family, and health.
                                Visit BPI MS Express Protect.
                            </Description3>
                        </Col>
                    </Row>
                    <Row fluid>
                        <Col>
                            <StyledButtonContainer>
                                <StyledButton  href="https://eprotect.bpims.com/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    onClick={handleButtonClick}
                                    >
                                </StyledButton>
                            </StyledButtonContainer>
                        </Col>
                    </Row>
                </Container>
            </StyledColumn>
        </LandingContainer>
    );
}
